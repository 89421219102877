
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import QRCode from "qrcodejs2";
import dayjs from "dayjs";
import StandardFlag from "@/components/scope/standard-flag.vue";
import t from "@common/src/i18n/t";
const base = namespace("base");
const store = namespace("store");
const campaign = namespace("campaign");
const marketing = namespace("marketing");
@Component({
  components: { StandardFlag }
})
@Table("loadListData")
export default class ServiceList extends Vue {
  dayjs = dayjs;
  @base.Action getDictionaryList;
  @campaign.Action queryCampaignList;
  @campaign.Action updateCampaignBeEnd;
  @store.State storeData;
  @marketing.Action queryFissionPage;
  @marketing.Action getFissionQrCodeUrl;
  @marketing.Action getWxSchemeUrl;
  @marketing.Action stopCampaign;
  emptyToLine = emptyToLine;
  splitThousands = splitThousands;
  t = t;
  orderList: any[] = [];
  storeQrUrl: string = "";
  searchForm = {
    campaignName: "",
    campaignStatus: ""
  };
  showComfirmPayment: boolean = false;
  showRegisterInvoice: boolean = false;
  showLinkQr: boolean = false;
  orderStatusList: any[] = [];
  staffList: any[] = [];
  created() {
    this.init();
    this.loadListData();
  }
  campaignFullUrl = "pages/fission/view";
  init() {
    // 活动状态枚举值
    this.getDictionaryList("campaign_status").then(data => {
      this.campaignStatusList = data;
    });
  }
  @Table.LoadDataMethod()
  loadListData() {
    return this.queryFissionPage({
      ...this.searchForm,
      pageNum: this.mix_pageNum,
      pageSize: this.mix_pageSize
    }).then(data => {
      this.orderList = data.data.list;
      return data;
    });
  }
  get searchList() {
    return [
      {
        label: t("v210801.activity-name"),
        type: "input",
        value: "",
        prop: "campaignName"
      },
      {
        label: t("v210801.active-state"),
        type: "select",
        selectList: this.campaignStatusList,
        value: "",
        prop: "campaignStatus"
      }
    ];
  }
  campaignStatusList: any = [];
  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }
  qrCode(url) {
    document.getElementById("qrcode").innerHTML = "";
    let qrcode = new QRCode("qrcode", {
      width: 120, //图像宽度
      height: 120, //图像高度
      colorDark: "#000000", //前景色
      colorLight: "#ffffff", //背景色
      typeNumber: 4,
      correctLevel: QRCode.CorrectLevel.H //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
    });
    qrcode.clear(); //清除二维码
    qrcode.makeCode(url); //生成另一个新的二维码
  }
  activeRow: any = {};
  handleComfirmCommand(command, row) {
    if (command === "push") {
      this.$router.push(`/marketing/campaign/fission-send-collect/${row.campaignCode}`);
    } else if (command === "edit") {
      this.$router.push(`/marketing/campaign/fission-campagin-add/${row.campaignCode}`);
    } else if (command === "end") {
      this.activeRow = row;
    } else if (command === "copy") {
      this.$router.push(`/marketing/campaign/fission-campagin-add?copyCode=${row.campaignCode}`);
    } else if (command === "copyLink") {
      console.log("[=============  ]");
      this.activeRow = row;
      this.showLinkQr = true;
      let page = `pages/fission/view`; // 生产
      // let page = `pages/fission/view?activeCode=${row.campaignCode}`;
      this.getFissionQrCodeUrl({
        campaignCode: row.campaignCode,
        page
      }).then(data => {
        console.log("[ data==== ]", data);
        this.storeQrUrl = data.data;
      });
      let pQuery = `activeCode=${row.campaignCode}`;
      let wechatParams = {
        businessCode: row.campaignCode,
        businessType: "fissionCampaign",
        path: "pages/fission/view", // 生产
        // path: "pages/index/index",
        query: decodeURIComponent(pQuery)
      };
      this.getWxSchemeUrl(wechatParams).then(res => {
        this.campaignFullUrl = res.data;
      });
    } else if (command === "downQRcode") {
      this.getFissionQrCodeUrl({
        campaignCode: row.campaignCode
        // page
      }).then(data => {
        console.log("[ data ]", data);
        this.storeQrUrl = data.data;
        this.handleDownloadQR();
      });
    }
  }
  handleEndCampaign() {
    this.stopCampaign({
      campaignCode: this.activeRow.campaignCode
    }).then(data => {
      this.$message.success(t("v210831.activity-ended"));
      this.loadListData();
    });
  }
  handleLinkData(row) {
    this.$router.push(`/marketing/campaign/fission-analysis/${row.campaignCode}?campaignType=collect`);
  }
  handleDownloadQR() {
    var a = document.createElement("a");
    a.download = "QR" + new Date().getTime() || t("v210801.download-picture-name");
    a.href = this.storeQrUrl;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  handleLinkOrderView(row) {
    let { href } = this.$router.resolve({
      path: `/service/view/${row.orderNo}`
    });
    window.open(href, "_blank");
  }
  tipCopy() {
    this.$message.success(t("v210831.copy-succeeded"));
  }
}
